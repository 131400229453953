.LoginPage {
  display: flex;
  height: 100%;
  background-color: #339999;

  .LoginForm {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #e8e8e8;
    box-shadow: 2px 2px 10px #ccc;
    margin: auto;

    > h2 {
      text-align: center;
    }

    > input {
      width: 100%;
      padding: 12px 20px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      transition: 0.5s;
      margin-bottom: 15px;
    }

    > button {
      width: 100%;
      background-color: #333;
      color: white;
      padding: 14px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 30px;

      &:disabled {
        background-color: #ccc;
        color: black;
        opacity: 0.7;
      }

      &:not(:disabled):hover {
        background-color: #ccc;
        color: black;
      }
    }
  }
}

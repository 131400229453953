.PageTabs {
  display: flex;
  margin: 50px 0;
  border-bottom: 1px solid rgb(213 213 213);

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 135px;
    height: 50px;
    background-color: white;
    color: #303030;
    text-transform: capitalize;
    margin: 0 5px;
    font-weight: 600;
    cursor: pointer;

    &:hover,
    &.selected {
      border-bottom: 4px solid rgb(102, 153, 153);
    }
  }
}

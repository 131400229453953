.NotificationsWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .NotificationList {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    z-index: 3;

    .NotificationList__item {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      border-bottom: 1px solid #eee;
      color: #313131;
      position: relative;
      cursor: pointer;

      .message {
        font-size: 15px;
        font-weight: 500;
      }

      .date {
        font-size: 12px;
        margin-top: 5px;
        margin-left: 5px;
      }

      &:hover {
        background-color: #eee;
      }

      &.warning-dot {
        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #f1ce49;
          left: 10px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
      }
    }
  }
}

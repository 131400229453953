.App {
  text-align: center;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 1.5rem;
}

.mb-3 {
  margin-bottom: 2rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 1.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 1.5rem;
}

.simple-button {
  border-radius: 7px;
  border: none;
  color: white;
  background: #7f8a93;
  font-size: 13px;
  text-align: center;
  padding: 5px 10px;
  opacity: 0.9;
  cursor: pointer;

  &.green {
    background: #339999;
  }

  &.blue {
    background: #6699cc;
  }

  &.red {
    background: #cc6666;
  }

  &.yellow {
    background: #cccc66;
  }

  &:hover {
    opacity: 1;
  }
}

.simple-input {
  border-radius: 6px;
  border: 1px solid #7f8a93;
  padding: 5px 10px;
  font-size: 12px;
  outline: none;

  .half-sized-input {
    width: 10px;
  }
}

.error-message {
  color: #cc6666;
  font-size: 13px;
  margin-top: 5px;

  &.small {
    font-size: 11px;
  }
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

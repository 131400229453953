.Properties {
  .tabs {
    display: flex;
    margin-bottom: 20px;

    .tab {
      background-color: white;
      color: black;
      padding: 5px 10px;
      cursor: pointer;
      border: none;
      border-bottom: 2px solid rgb(213 213 213);
      font-size: 17px;
      font-weight: 500;

      &.active {
        border-bottom: 2px solid rgb(102, 153, 153);
        font-weight: 600;
      }

      &:hover {
        border-bottom: 2px solid rgb(102, 153, 153);
      }
    }
  }
}

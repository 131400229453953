.SelectableCell {
  .SelectableCell__control {
    max-height: 60px;
    max-width: 400px;

    .SelectableCell__value-container {
      max-height: 58px;
      overflow-y: auto;
    }
  }
}

.Listing {
  border-spacing: 1px;
  margin-top: 15px;
  background: #f2f2f2;
  border: none;
  margin-bottom: 15px;
  width: 100%;

  thead {
    background: #bfbfbf;
    color: black;
    font-size: 12px;

    th {
      padding: 8px 10px;
    }
  }

  tbody {
    color: black;

    td {
      font-size: 12px;
      padding: 5px 10px;
      color: black;
    }
  }
}

.pageStyles {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  max-width: 50%;
}

.inputStyles {
  margin: auto;
  -webkit-appearance: none;
}

.my-input {
  color: transparent; /* Make the text transparent when no file is selected */
}

.my-input:not(:focus)::before {
  content: "No file selected";
  color: #999; /* Set the color of the text to a light gray */
}

.my-input:focus::before,
.my-input:valid::before {
  content: attr(value);
  color: #000; /* Set the color of the text to black when a file is selected */
}

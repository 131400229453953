.ModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .Modal {
    display: flex;
    flex-direction: column;
    width: 500px;
    min-height: 500px;
    background: white;
    border-radius: 10px;
    color: #515d68;
    position: relative;
    padding: 15px 20px;
    max-height: 100vh;
    overflow: auto;

    .close {
      color: #aaa;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      top: 5px;
      right: 15px;
      cursor: pointer;
    }
  }
}

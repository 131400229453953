.form-wrapper {
  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div.form-field {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      width: 40%;

      &.full-width {
        width: 100%;
      }

      label {
        margin-bottom: 5px;
      }

      input {
        border-radius: 4px;
        border: 1px solid hsl(0, 0%, 80%);
        min-height: 20px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
          "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        color: hsl(0, 0%, 20%);
        font-size: 16px;
      }
    }

    button {
      width: 100%;
      height: 40px;
      margin-top: 20px;
    }
  }
}

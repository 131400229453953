.Layout {
  display: flex;
  width: 100%;
  height: 100%;

  .sidebar {
    display: flex;
    flex-direction: column;
    background-color: #339999;
    width: 240px;

    .logo-image {
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        margin: 20px 15px;
      }
    }

    .tab-options {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      .option {
        text-decoration: none;
        font-size: 15px;
        line-height: 1;
        color: white;
        padding: 20px 15px;
        font-weight: 500;

        &.active,
        &:hover {
          background-color: #4d8383;
        }
      }
    }

    .logout-button {
      margin: auto 15px 20px 15px;
      appearance: none;
      background: none;
      border: none;
      text-decoration: underline;
      color: white;
      font-size: 0.9em;
    }
  }

  .notification-bell {
    position: absolute;
    margin-left: auto;
    top: 20px;
    right: 40px;
    cursor: pointer;

    .image-wrapper {
      position: relative;

      > img {
        width: 30px;
        height: 30px;
      }

      .unread-warning {
        position: absolute;
        top: 0;
        right: -1px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: indianred;
        font-size: 10px;
        text-align: center;
        color: white;
      }
    }
  }

  .tab-content {
    width: calc(100% - 240px);
    padding: 20px;
    overflow-y: scroll;
    position: relative;
  }
}
